.pc_Personalbody {
  position: relative;
  height: 640px;
  width: 100%;
  flex: 1;
  display: flex;
  background-image: url("../../../../../img/pc/PC_Personalbackimg.png");
  background-repeat: "no-repeat";
  background-size: 100% 100%;
}
.pc_PersonalblackMask {
  background: rgba(61, 55, 68, 1);
  opacity: 0.8;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 888;
}
.pc_PersonalContent {
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: row;
  padding-left: 25%;
  padding-right: 15%;
  justify-content: space-between;
  width: 100%;
}
p {
  padding: 0;
  margin: 0;
}
