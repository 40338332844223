.bottom {
    position: fixed;
    height: 60px;
    width: 80%;
    flex: 1;
    display: flex;
    background-image: url('../../../../img/phone/ImmediatelyDown.png');
    background-repeat: 'no-repeat';
    background-size: 100% 100%;
    bottom: 20px;
    z-index: 999;
    left: 10%;
}

.download {

    position: fixed;
    left: 50%;
    bottom: 1rem;
    width: 16rem;
    margin-left: -8rem;
}