.phone_Teambody{
    position: relative;
    width: 100%;
    height: 674px;
    background-image: url('../../../../../img/phone/phone_scoreBackimg.png');
    background-repeat:'no-repeat';
    background-size:100% 100%;
   }
 .Team_content{
       position: absolute;
       z-index: 999;
       top: -12px;
       left: 0px;
       display: flex;
       flex-direction: column;
       align-items: center;
   }
.phone_TeamblackMask{
    background: #3D3744;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index:666;
}
 p{
    padding: 0;
    margin: 0;
  }