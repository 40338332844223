.pc_formbody{
    position: relative;
    height:640px;
    width: 100%;
    flex: 1;
    display: flex;
    background-image: url('../../../../../img/pc/contact.png');
    background-repeat:'no-repeat';
    background-size:100% 100%;
   }
   .pc_FormblackMask{
    background: rgba(61,55,68,1);;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 888;
}
.pc_FormContent{
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
 p{
    padding: 0;
    margin: 0;
  }