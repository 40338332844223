.pc_Teambody{
    position: relative;
    height:640px;
    width: 100%;
    background-image: url('../../../../../img/pc/teamManager.png');
    background-repeat:'no-repeat';
    background-size:100% 100%;
   }
.pc_TeamblackMask{
    background: #3D3744;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 888;
  }
.pc_TeamContent{
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: row;
    padding-left:25%;
    padding-right: 15%;
    justify-content: space-between;
}
.iconlist{
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-top: 40px;
}
.teamicon{
  margin-right: 80px;
  width: 48px;
  height: 48px;
}
 p{
    padding: 0;
    margin: 0;
  }