.phone_body{
    position: relative;
    width: 100%;
    height: 430px;
    background-image: url('../../../../../img/pc/HeadBackimg.png');
    background-repeat:'no-repeat';
    background-size:100% 100%;
   }
 .phone_logo1{
       width: 52px;
       height: 16px;
       margin: auto;
   }
   .phone_logomenu{
      display: flex;
      margin-top:14px;
      justify-content: start;
   }
   .phone_download{
       width: 93px;
       height: 30;
   }
   .menuhiden{
    list-style: none;
    position: absolute; 
    margin: 0;
    padding: 0;
    height:0px;
    overflow: hidden;
    background:rgba(112,140,151,0.6);
    width: 100px;
    display: flex;
    right: 0px;
    flex-direction: column;
    justify-content: space-around;
    z-index: 999;
    border-radius: 4px;
   }
   .phone_menu{
    right: 18px;
    position: absolute;
  }
  .phone_menu:hover>.menuhiden{
    height: 150px;
    transition: height 0.8s ease;
  }
   .menuImg{
    width: 12px;
    height: 10px;
   }
.logoMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 81px;
    padding-right: 81px;
    padding-top: 26px;
}
.phone_logo2{
height: 17%;
width: 53%;
align-self: center;
}
.phone_black{
    background: #000;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 888;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text{
color: #FFFFFF;
font-size: 14px;
}


.downloaditem{
   margin-left: 16px;
}

 p{
    padding: 0;
    margin: 0;
  }