.App {
  text-align: center;
}
.border{
  background: #0000ff;
  display: flex;
  justify-content: flex-end;
}
.fa{
 position: relative;
 }
.ho:hover+.hob{
  height: 150px;
  transition: height 1s ease;
 }
.hob{
  position: absolute;
  right: 20px;
  height: 0;
  overflow: hidden;
  background:rgba(112,140,151,0.6);
}

